<template>
  <button class="btn-reload" title="Rafraichir" @click="$emit('click', $event)">
    <mdicon name="reload" />
  </button>
</template>
<script>
export default {

};
</script>
<style lang="scss" scoped>
.btn-reload {
  box-shadow: none;
  background: transparent;
  color: #888;
  &:hover {
    color: #111;
  }
}
</style>
